import { useState,useEffect  } from "react";
import ArkreenMinCard from '@/components/ArkreenMinCard';
import ArkreenGrowthTrendCard from '@/components/ArkreenGrowthTrendCard';
import ArkreenMinViewTable from '@/components/ArkreenMinViewTable';
import { Col, Row } from 'antd';
import {MinerLineIcon,PEMinerLineIcon,PlugMinerLineIcon,PvCapacityLineIcon,GreenEnergyLineIcon,ImpactLineIcon,BlockLineIcon,TransactionLineIcon,CO2Icon} from '@/icons/ArkreenIcon'
import StatApi from '../api/StatApi'
import BlockApi from '../api/BlockApi'
import TransactionApi from '../api/TransactionApi'
import {formatTxType,formatElectricity,formatPower} from '@/utils/DataFormatUtils'
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMain.css'
import moment from 'moment'

//const isShowAccount = process.env.REACT_APP_SHOW_ACCOUNTS
// paratest
function Dashboard(){

  const [networkInfo,setNetworkInfo] = useState({
    accounts:0,
    capacity: 0,
    miners: 0,
    reGenerators: 0,
    plugs: 0,
    greenEnergy: 0
  })
  const [txList,setTxList] = useState<any>([])
  const [blockList,setBlockList] = useState<any>([])
  const [minerGrowthData, setMinerGrowthData] = useState<any>([]);
  const [pvCapacityGrowthData, setPVCapacityGrowthData] = useState<any>([]);
  const [greenEnergyData, setGreenEnergyData] = useState<any>([]);
  const [CO2, setCO2] = useState<any>([]);
  const timeArr = moment(new Date()).format('LLLL').split(",");
  const [deviceDaily, setDeviceDaily] = useState<any>({
    miner: '0',
    electricityMiners: '0',
    powerGenerationMiners: '0',
    capacity: '0 kW',
    energy: '0 kWh',
    time: timeArr[0]+","+timeArr[1],
    co2:'0 ton ',
  });
  const [minerGrowthChartMetaCfg, setMinerGrowthChartMetaCfg] = useState<any>()
  const [pvCapacityGrowthChartMetaCfg, setPVCapacityGrowthChartMetaCfg] = useState<any>()
  const [greenEnergyGrowthChartMetaCfg, setGreenEnergyGrowthChartMetaCfg] = useState<any>({})
  const [co2GrowthChartMetaCfg, setCo2GrowthChartMetaCfg] = useState<any>({})
  const [impact,setImpact] = useState<any>()
  //
  function stateNetworkTrend() {

    StatApi.stateNetworkTrend().then((rsp:any) => {
      if(!rsp.error){
        if(rsp.result){
          const info = {
            accounts: rsp.result.owners?rsp.result.owners:0,
            capacity: rsp.result.peakPowerTotal?rsp.result.peakPowerTotal:0,
            miners: rsp.result.miners?rsp.result.miners:0,
            reGenerators: rsp.result.powerGenerationMiners?rsp.result.powerGenerationMiners:0,
            plugs: rsp.result.electricityMiners?rsp.result.electricityMiners:0,
            greenEnergy: rsp.result.energy?rsp.result.energy:0,
          }
          const co = Number(rsp.result.energy?((rsp.result.energy/1000/1000)*7.09/10000):0)
          setImpact(Number(Number(co).toFixed(2)).toLocaleString()+" ton ")
          setNetworkInfo(info)
        }
      }
    })
  }
  function handleStatDeviceDaily(){
    StatApi.statDeviceDaily().then((rsp:any) => {
      if(!rsp.error && rsp.result.length>0){
        const MinerGrowth:any[] = []
        const Capacity:any[] = []
        const Energy:any[]  = []
        const CO2:any[]  = []
        const deviceDaily = {
          miner: '0',
          electricityMiners: '0',
          powerGenerationMiners: '0',
          capacity: '0 kW',
          energy: '0 kWh',
          time: '',
          co2:'0 ton ',
        }
        let isKwh = false;
        let isKg = false;
        for (let index = rsp.result.length-1; index >=0 ; index--) {
          const item:any = rsp.result[index];
          MinerGrowth.push({date:item.dataDate,value:item.minerTotal});
          if (item.peakPowerTotal > 0) {
            Capacity.push({date:item.dataDate,value:item.peakPowerTotal/ 1000 });
          }
         // Energy.push({date:item.dataDate,value:item.energyGenerationTotal / 1000 / 1000});
          deviceDaily.miner = Number(item.minerTotal).toLocaleString()
          deviceDaily.electricityMiners = Number(item.electricityMiners).toLocaleString()
          deviceDaily.powerGenerationMiners = Number(item.powerGenerationMiners).toLocaleString()
          deviceDaily.capacity = Number(Number(item.peakPowerTotal/1000/1000).toFixed(1)).toLocaleString()+" kW";
          //deviceDaily.energy = Number(Number(item.energyGenerationTotal/1000/1000).toFixed(2)).toLocaleString()+" kWh";
          const energy = item.energyGenerationTotal/1000/1000
          if(energy<10000){
            Energy.push({date:item.dataDate,value:energy});
            deviceDaily.energy = Number(Number(energy).toFixed(2)).toLocaleString()+" kWh";
            isKwh = true
          }else {
            if(isKwh){
              Energy.push({date:item.dataDate,value:energy*1000});
            }else{
              Energy.push({date:item.dataDate,value:energy*1000});
            }
            deviceDaily.energy = Number(Number(energy/1000).toFixed(2)).toLocaleString()+" MWh";
          }
          const timeArr = moment(item.dataDate).format('LLLL').split(",");
          deviceDaily.time = timeArr[0]+","+timeArr[1]
          const co = Number((item.energyGenerationTotal/1000/1000)*7.09/10000).toFixed(2);
          const test = false
          if(test){
            if(Number(co)<10){
              const temp = Number(Number(co)*1000).toFixed(2)
              deviceDaily.co2 = Number(temp).toLocaleString()+" kg "
              CO2.push({date:item.dataDate,value:Number(temp)})
              isKg = true
            }else {
              if(isKg){
                CO2.push({date:item.dataDate,value:Number(co)})
              }else{
                CO2.push({date:item.dataDate,value:Number(co)})
              }
              deviceDaily.co2 = Number(Number(co).toFixed(2)).toLocaleString()+" ton "
            }
          }else {
            deviceDaily.co2 = Number(Number(co).toFixed(2)).toLocaleString()+" ton "
            CO2.push({date:item.dataDate,value:Number(co)})
          }
        }
        const slice = -14;
        const tempMinerGrowthData = MinerGrowth.slice(slice)
        const tempPVCapacityGrowthData = Capacity.slice(slice)
        const tempGreenEnergyData = Energy.slice(slice)
        const tempCo2Data = CO2.slice(slice)

        setMinerGrowthData(MinerGrowth.slice(slice));
        setPVCapacityGrowthData(Capacity.slice(slice));
        setGreenEnergyData(Energy.slice(slice));
        setCO2(CO2.slice(slice));
        setDeviceDaily(deviceDaily);
        //
        tempMinerGrowthData.sort(function(a,b){return a.value-b.value})
        const minMinerGrowthData = tempMinerGrowthData[0].value
        const maxMinerGrowthData = tempMinerGrowthData[tempMinerGrowthData.length-1].value
        const minerGrowthInterval = (maxMinerGrowthData - minMinerGrowthData)/2
        const tempMinerGrowthChartMetaCfg = {value: { min: minMinerGrowthData-minerGrowthInterval, max: maxMinerGrowthData+minerGrowthInterval}}
        setMinerGrowthChartMetaCfg(tempMinerGrowthChartMetaCfg)

        tempPVCapacityGrowthData.sort(function(a,b){return a.value-b.value})
        const minPVCapacityGrowthData = tempPVCapacityGrowthData[0].value
        const maxPVCapacityGrowthData = tempPVCapacityGrowthData[tempPVCapacityGrowthData.length-1].value
        const pvCapacityGrowthInterval = (maxPVCapacityGrowthData - minPVCapacityGrowthData)/2
        const tempPVCapacityGrowthChartMetaCfg = {value: { min: minPVCapacityGrowthData-pvCapacityGrowthInterval, max: maxPVCapacityGrowthData+pvCapacityGrowthInterval}}
        setPVCapacityGrowthChartMetaCfg(tempPVCapacityGrowthChartMetaCfg)

        tempGreenEnergyData.sort(function(a,b){return a.value-b.value})
        const minGreenEnergyData = tempGreenEnergyData[0].value
        const maxGreenEnergyData = tempGreenEnergyData[tempGreenEnergyData.length-1].value
        const greenEnergyInterval = (maxGreenEnergyData - minGreenEnergyData)/2
        const tempGreenEnergyGrowthChartMetaCfg = {value: { min: minGreenEnergyData-greenEnergyInterval, max: maxGreenEnergyData+greenEnergyInterval}}
        setGreenEnergyGrowthChartMetaCfg(tempGreenEnergyGrowthChartMetaCfg)

        tempCo2Data.sort(function(a,b){return a.value-b.value})
        const minCo2Data = tempCo2Data[0].value
        const maxCo2Data = Number(tempCo2Data[tempCo2Data.length-1].value)
        const co2Interval = (maxCo2Data - minCo2Data)/2
        setCo2GrowthChartMetaCfg({value: { min: minCo2Data-co2Interval, max: maxCo2Data+co2Interval}})
      }
    })
  }

  function getTransactionList(){
    TransactionApi.getTransactionList({limit:10}).then((rsp:any) => {
      if(!rsp.error){
        const list = new Array(rsp.result.length); 
          rsp.result.forEach((item:any) => {
            const tx = {
              label: item.hash,
              value: formatTxType(item.type),
              timestamp: item.createTime
            }
            list.push(tx);
          })
          setTxList(list)
        }
      })
  }

  function getBlockList(){
    BlockApi.getBlockList({limit:10}).then((rsp:any) => {
      if(!rsp.error){
        const list:any = []; 
          rsp.result.forEach((item:any) => {
            const tx = {
              label: "#"+(item.height?Number(item.height).toLocaleString():0),
              value: ''+(item.txAmount?item.txAmount:'0')+" Transactions",
              timestamp: item.createTime
            }
            list.push(tx);
          })
          setBlockList(list)
        }
    })
  }
  
  useEffect(() => {
    stateNetworkTrend();
    getTransactionList();
    getBlockList();
    handleStatDeviceDaily();
  },[])
  

  return (
    <div className="arkreen-main">
      <Row gutter={[20,20]}>
        {
            //isShowAccount === 'true'?(<Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}><ArkreenMinCard title='Accounts' icon={<AccountLineIcon />} content={networkInfo.accounts!==undefined?Number(networkInfo.accounts).toLocaleString():""}/></Col>):<></>
        }
        <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <ArkreenMinCard title='Peak Power' icon={<PvCapacityLineIcon/>} content={networkInfo.capacity!==undefined?formatPower(Number(networkInfo.capacity),1):''}/>
        </Col>
        <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <ArkreenMinCard title='Green Energy' icon={<GreenEnergyLineIcon/>} content={networkInfo.greenEnergy!==undefined?formatElectricity(Number(networkInfo.greenEnergy),1):''}/>
        </Col>
        <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <ArkreenMinCard title='Impact' icon={<ImpactLineIcon/>} content={impact!==undefined?<span><span>{impact}</span><span><CO2Icon /></span></span>:''} titleColor="#33A761" valueColor="#00913A" style={{background:"#E6F4EB"}}/>
        </Col>
      </Row>
      <Row gutter={[20,20]} style={{paddingTop:'20px'}}>
        <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <ArkreenMinCard title='Miners' icon={<MinerLineIcon/>} content={networkInfo.miners!==undefined?networkInfo.miners.toLocaleString():''}/>
        </Col>
        <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <ArkreenMinCard title='RE Generators' icon={<PEMinerLineIcon/>} content={networkInfo.reGenerators!==undefined?networkInfo.reGenerators.toLocaleString():''}/>
        </Col>
        <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <ArkreenMinCard title='Plugs' icon={<PlugMinerLineIcon/>} content={networkInfo.plugs!==undefined?networkInfo.plugs.toLocaleString():''}/>
        </Col>
      </Row>
      <Row gutter={[20,20]} style={{paddingTop:'20px'}}>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <ArkreenGrowthTrendCard fullButton={true} routerLink='/stat/miner' type={2} title='Miner Growth' metaCfg={minerGrowthChartMetaCfg} value={deviceDaily.miner} time={deviceDaily.time} data={minerGrowthData}/>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <ArkreenGrowthTrendCard fullButton={true} routerLink='/stat/capacity' unit="" type={2} title='Peak Power Growth' metaCfg={pvCapacityGrowthChartMetaCfg} value={deviceDaily.capacity} time={deviceDaily.time} data={pvCapacityGrowthData}/>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <ArkreenGrowthTrendCard fullButton={true} routerLink='/stat/energy' unit="" type={4} title='Green Energy Data Uploaded' metaCfg={greenEnergyGrowthChartMetaCfg} value={deviceDaily.energy} time={deviceDaily.time} data={greenEnergyData}/>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <ArkreenGrowthTrendCard fullButton={true} routerLink='/stat/impact' unit="" type={4} title='Total Impact' metaCfg={co2GrowthChartMetaCfg} value={deviceDaily.co2} time={deviceDaily.time} data={CO2} />
        </Col>
      </Row>
      <Row gutter={[20,20]} style={{paddingTop:'20px'}}>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <ArkreenMinViewTable hrefAll="/txs" href="/tx" title='Transactions' buttonAble={true} buttonName='view all' valueColor="#00913A" dataList={txList} icon={<TransactionLineIcon />}/>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <ArkreenMinViewTable hrefAll="/blocks" href="/block" title='Blocks' buttonAble={true} buttonName='view all' dataList={blockList} icon={<BlockLineIcon/>} />
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
