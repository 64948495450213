import HttpUtils from '../utils/HttpUtils'

async function getTransactionList(data?: any) {
    const params = {
        type:data?.type,
        offset:data?.offset?data.offset:1,
        limit:data?.limit?data.limit:25,
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getTransactionListByType',params);
}

async function getMinerTransactionListByType(data?: any) {
    const params = {
        address:data.address,
        type:data?.type,
        offset:data?.offset?data.offset:1,
        limit:data?.limit?data.limit:25,
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getMinerTransactionListByType',params);
}

async function getMinerReportList(data?: any) {
    const params = {
        address:data.address,
        offset:data?.offset?data.offset:1,
        limit:data?.limit?data.limit:25,
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getMinerReportList',params);
}

async function getReportList(data: any) {
    const type = data.type
    const params = {
        address:data.address,
        offset:data?.offset?data.offset:1,
        limit:data?.limit?data.limit:25,
    }
    let method:string = 'net_getMinerReportList'
    if(type === 9){
        method = 'net_getPocMinerReportList'
    }else if(type === 10){
        method = 'net_getPlantMinerReportList'
    }
    return await HttpUtils.sendJsonRPC('/v1',method,params);
}

async function getPocMinerReportList(data?: any) {
    const params = {
        address:data.address,
        offset:data?.offset?data.offset:1,
        limit:data?.limit?data.limit:25,
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getPocMinerReportList',params);
}

async function getPlantMinerReportList(data?: any) {
    const params = {
        address:data.address,
        offset:data?.offset?data.offset:1,
        limit:data?.limit?data.limit:25,
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getPlantMinerReportList',params);
}

async function getTransactionByHash(hash: string) {
    const params = {
        hash:hash
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getTransactionByHash',params);
}

async function getTransactionDataByHash(hash: string) {
    const params = {
        hash:hash
    }
    return await HttpUtils.sendJsonRPC('v1','net_getTransactionDataByHash',params);
}


async function getBlockTransactionsByHash(hash: string) {
    const params = {
        hash:hash
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getBlockTransactionsByHash',params);
}

async function getMinerRewardList(data: any) {
    const params = {
        address:data.address,
        offset:data?.offset?data.offset:1,
        limit:data?.limit?data.limit:25,
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getMinerRewardList',params);
}


const TransactionApi ={
    getTransactionList:getTransactionList,
    getMinerTransactionListByType:getMinerTransactionListByType,
    getMinerReportList:getMinerReportList,
    getTransactionByHash:getTransactionByHash,
    getTransactionDataByHash:getTransactionDataByHash,
    getBlockTransactionsByHash:getBlockTransactionsByHash,
    getMinerRewardList:getMinerRewardList,
    getPocMinerReportList:getPocMinerReportList,
    getPlantMinerReportList:getPlantMinerReportList,
    getReportList:getReportList
}

export default TransactionApi;