import React from "react";
import { Button,Skeleton  } from 'antd';
import '../common/css/ArkreenMinViewTable.css'
import {formatVisualizationDate,formatLongString} from '@/utils/DataFormatUtils'
import { useNavigate } from 'react-router-dom'

type Item = {
    label: string,
    value: string,
    timestamp: number
}

type cardProps = {
    icon?:React.ReactNode,
    title: string,
    buttonAble?: boolean | false,
    buttonName?: string
    href?: string,
    dataList: Item[],
    valueColor?:string
    hrefAll?:string
}

export default function ArkreenMinViewTable(prop: cardProps): JSX.Element {
    const navigate  = useNavigate()
    function goto(label:string){
        if(prop.href){
            label = label.replace('#','')
            label = label.replace(',','')
            navigate(prop.href+'/'+label)
        }
    }

    return (
        <div className="min-view-table-body">
            <div className="min-view-table-header">
                <div className="min-view-table-title">{prop.title}</div>
                <div className="min-view-table-extra">
                    <Button href={prop.hrefAll} type="link" style={{"padding": 0}}><span>{prop.buttonName?prop.buttonName:'Button'}</span></Button>
                </div>
            </div>
            {
                (prop.dataList && prop.dataList.length>0)?prop.dataList.map((item,index) => {
                    return (
                        <div key={item.label+'_'+index}>
                            <div className="min-view-table-td" onClick={() => goto(item.label)}>
                                <div className="min-view-table-icon">{prop.icon}</div>
                                <div>
                                    <div className="content-label">
                                        <div className="content-label-left">
                                            <span className="content-td-link">{formatLongString(item.label,7,7)}</span>
                                            <div><span style={prop.valueColor?{color:prop.valueColor}:{color:'#707079'}}>{item.value}</span></div>
                                        </div>
                                        <div className="content-label-right">{formatVisualizationDate(item.timestamp)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) 
                }):(
                    <div style={{padding:'6px'}}>
                        <Skeleton.Input style={{height:'44px',marginBlock:'6px'}}  active block={true} size='large' />
                        <Skeleton.Input style={{height:'44px',marginBlock:'6px'}}  active block={true} size='large' />
                        <Skeleton.Input style={{height:'44px',marginBlock:'6px'}}  active block={true} size='large' />
                        <Skeleton.Input style={{height:'44px',marginBlock:'6px'}}  active block={true} size='large' />
                        <Skeleton.Input style={{height:'44px',marginBlock:'6px'}}  active block={true} size='large' />
                        <Skeleton.Input style={{height:'44px',marginBlock:'6px'}}  active block={true} size='large' />
                        <Skeleton.Input style={{height:'44px',marginBlock:'6px'}}  active block={true} size='large' />
                        <Skeleton.Input style={{height:'44px',marginBlock:'6px'}}  active block={true} size='large' />
                        <Skeleton.Input style={{height:'44px',marginBlock:'6px'}}  active block={true} size='large' />
                        <Skeleton.Input style={{height:'44px',marginBlock:'6px'}}  active block={true} size='large' />
                    </div>
                )
            }
        </div>
    )
}
    