
import { useNavigate } from 'react-router-dom'
import {Error404Icon,BackIcon} from '@/icons/ArkreenIcon'
import ArkreenButton from '../components/ArkreenButton'
import '../common/css/ArkreenMain.css'

function Page404(){

  const navigate  = useNavigate()

  function backHome(){
    navigate("/")
  }

  return (
    <div >
        <div className='arkreen-404'>
          <Error404Icon/>
          <div className='arkreen-404-title'>Oh oh!</div>
          <div className='arkreen-404-info'>It seems you tried to reach something we don’t know about...</div>
          <div  className='arkreen-404-btn'>
            <ArkreenButton onClick={() => backHome()} className='arkreen-404-back'><BackIcon/> Go back home</ArkreenButton>
          </div>
        </div>
    </div>
  );
}

export default Page404;
