import {Area, AreaConfig} from '@ant-design/plots';
import { ListItem } from '@antv/component';
import moment from 'moment'
import {formatNumberValue} from '@/utils/DataFormatUtils'
type cardProps = {
    data: any,
    xField: string,
    yField: string,
    lineColor:string,
    height:string
    unit?:string
    type?:number
    metaCfg?: any
}
// type 默认为普通数值
// type = 1  为Power
// type = 2  为Energy
// type = 3  为重量

export default function ArkreenAreaChart(prop: cardProps): JSX.Element {
    const xTickCount = 7
    const yTickCount = 5
    const data = prop.data

    const config:AreaConfig = {
        data,
        smooth: true,
        xField: prop.xField,
        yField: prop.yField,
        startOnZero: true,
        meta: prop.metaCfg,
        xAxis: {
            range: [0, 1],
            tickCount: xTickCount,
            label: {
                formatter: (text: string, item: ListItem, index: number) => {
                    if(index === 0 || index === data.length-1) {
                        return null
                    }
                    else {
                        return moment(text, "YYYY-MM-DD").format("MM/DD")
                    }
                }
            },
            line: null,
            tickLine: null,
        },
        yAxis: {
            range: [0, 1],
            grid: null,
            position: "right",
            tickCount: yTickCount,
            label: {
                formatter: (text: string, item: ListItem, index: number) => {
                    if(index === 0) {
                        return null
                    }else {
                        if(prop.type) {
                            return formatNumberValue(Number(text),prop.type);
                        } else {
                            return formatNumberValue(Number(text),2);
                        }
                    }
                }
            },
        },
        line: {
            color: '#00913A'
        },
        areaStyle: () => {
            return {
                fill: 'l(270) 0:#ffffff 0.5:#00913A 1:#00913A',
            };
        },
        tooltip:{
            formatter:(datum: any) => {
                return { name: datum.date , value: Number(Number(datum.value).toFixed(3)).toLocaleString()+(prop.unit?prop.unit:'')  };
            }
        }
    };
  
    return (<Area style={{height:prop.height}} {...config} />)
}

    