"use client"

import * as Country from 'country-flag-icons/react/3x2'
import { useEffect, useState } from 'react'
import {LoadingOutlined} from '@ant-design/icons';

type ArkreenCountryType = {
    code:string
    width?:string
    height?:string
}


export default function ArkreenCountry(prop:ArkreenCountryType): JSX.Element {

    const [country,setCountry] = useState<any>(<LoadingOutlined/>)

    const style = {width:prop.width?prop.width:'32px',height:prop.height?prop.height:'24px',background:'#E6F4EB55',padding:'1px 4px',borderRadius:'3px'}

    function init() {
        const code = prop.code
        if(code === 'ZW'){
            setCountry(<Country.ZW style={style}/>)
        }else if(code === 'ZM'){
            setCountry(<Country.ZM style={style}/>)
        }else if(code === 'ZA'){
            setCountry(<Country.ZA style={style}/>)
        }else if(code === 'YT'){
            setCountry(<Country.YT style={style}/>)
        }else if(code === 'YE'){
            setCountry(<Country.YE style={style}/>)
        }else if(code === 'XK'){
            setCountry(<Country.XK style={style}/>)
        }else if(code === 'WS'){
            setCountry(<Country.WS style={style}/>)
        }else if(code === 'WF'){
            setCountry(<Country.WF style={style}/>)
        }else if(code === 'VU'){
            setCountry(<Country.VU style={style}/>)
        }else if(code === 'VN'){
            setCountry(<Country.VN style={style}/>)
        }else if(code === 'AC'){
            setCountry(<Country.AC style={style}/>)
        }else if(code === 'AD'){
            setCountry(<Country.AD style={style}/>)
        }else if(code === 'AE'){
            setCountry(<Country.AE style={style}/>)
        }else if(code === 'AF'){
            setCountry(<Country.AF style={style}/>)
        }else if(code === 'AG'){
            setCountry(<Country.AG style={style}/>)
        }else if(code === 'AI'){
            setCountry(<Country.AI style={style}/>)
        }else if(code === 'AL'){
            setCountry(<Country.AL style={style}/>)
        }else if(code === 'AM'){
            setCountry(<Country.AM style={style}/>)
        }else if(code === 'AO'){
            setCountry(<Country.AO style={style}/>)
        }else if(code === 'AQ'){
            setCountry(<Country.AQ style={style}/>)
        }else if(code === 'AR'){
            setCountry(<Country.AR style={style}/>)
        }else if(code === 'AS'){
            setCountry(<Country.AS style={style}/>)
        }else if(code === 'AT'){
            setCountry(<Country.AT style={style}/>)
        }else if(code === 'AU'){
            setCountry(<Country.AU style={style}/>)
        }else if(code === 'AW'){
            setCountry(<Country.AW style={style}/>)
        }else if(code === 'AX'){
            setCountry(<Country.AX style={style}/>)
        }else if(code === 'AZ'){
            setCountry(<Country.AZ style={style}/>)
        }else if(code === 'BA'){
            setCountry(<Country.BA style={style}/>)
        }else if(code === 'BB'){
            setCountry(<Country.BB style={style}/>)
        }else if(code === 'BD'){
            setCountry(<Country.BD style={style}/>)
        }else if(code === 'BE'){
            setCountry(<Country.BE style={style}/>)
        }else if(code === 'BF'){
            setCountry(<Country.BF style={style}/>)
        }else if(code === 'BG'){
            setCountry(<Country.BG style={style}/>)
        }else if(code === 'BH'){
            setCountry(<Country.BH style={style}/>)
        }else if(code === 'BI'){
            setCountry(<Country.BI style={style}/>)
        }else if(code === 'BJ'){
            setCountry(<Country.BJ style={style}/>)
        }else if(code === 'BL'){
            setCountry(<Country.BL style={style}/>)
        }else if(code === 'BM'){
            setCountry(<Country.BM style={style}/>)
        }else if(code === 'BN'){
            setCountry(<Country.BN style={style}/>)
        }else if(code === 'BO'){
            setCountry(<Country.BO style={style}/>)
        }else if(code === 'BQ'){
            setCountry(<Country.BQ style={style}/>)
        }else if(code === 'BR'){
            setCountry(<Country.BR style={style}/>)
        }else if(code === 'BS'){
            setCountry(<Country.BS style={style}/>)
        }else if(code === 'BT'){
            setCountry(<Country.BT style={style}/>)
        }else if(code === 'BV'){
            setCountry(<Country.BV style={style}/>)
        }else if(code === 'BW'){
            setCountry(<Country.BW style={style}/>)
        }else if(code === 'BY'){
            setCountry(<Country.BY style={style}/>)
        }else if(code === 'BZ'){
            setCountry(<Country.BZ style={style}/>)
        }else if(code === 'CA'){
            setCountry(<Country.CA style={style}/>)
        }else if(code === 'CC'){
            setCountry(<Country.CC style={style}/>)
        }else if(code === 'CD'){
            setCountry(<Country.CD style={style}/>)
        }else if(code === 'CF'){
            setCountry(<Country.CF style={style}/>)
        }else if(code === 'CG'){
            setCountry(<Country.CG style={style}/>)
        }else if(code === 'CH'){
            setCountry(<Country.CH style={style}/>)
        }else if(code === 'CI'){
            setCountry(<Country.CI style={style}/>)
        }else if(code === 'CK'){
            setCountry(<Country.CK style={style}/>)
        }else if(code === 'CL'){
            setCountry(<Country.CL style={style}/>)
        }else if(code === 'CM'){
            setCountry(<Country.CM style={style}/>)
        }else if(code === 'CN'){
            setCountry(<Country.CN style={style}/>)
        }else if(code === 'CO'){
            setCountry(<Country.CO style={style}/>)
        }else if(code === 'CR'){
            setCountry(<Country.CR style={style}/>)
        }else if(code === 'CU'){
            setCountry(<Country.CU style={style}/>)
        }else if(code === 'CV'){
            setCountry(<Country.CV style={style}/>)
        }else if(code === 'CW'){
            setCountry(<Country.CW style={style}/>)
        }else if(code === 'CX'){
            setCountry(<Country.CX style={style}/>)
        }else if(code === 'CY'){
            setCountry(<Country.CY style={style}/>)
        }else if(code === 'CZ'){
            setCountry(<Country.CZ style={style}/>)
        }else if(code === 'DE'){
            setCountry(<Country.DE style={style}/>)
        }else if(code === 'DJ'){
            setCountry(<Country.DJ style={style}/>)
        }else if(code === 'DK'){
            setCountry(<Country.DK style={style}/>)
        }else if(code === 'DM'){
            setCountry(<Country.DM style={style}/>)
        }else if(code === 'DO'){
            setCountry(<Country.DO style={style}/>)
        }else if(code === 'DZ'){
            setCountry(<Country.DZ style={style}/>)
        }else if(code === 'EC'){
            setCountry(<Country.EC style={style}/>)
        }else if(code === 'EE'){
            setCountry(<Country.EE style={style}/>)
        }else if(code === 'EG'){
            setCountry(<Country.EG style={style}/>)
        }else if(code === 'EH'){
            setCountry(<Country.EH style={style}/>)
        }else if(code === 'ER'){
            setCountry(<Country.ER style={style}/>)
        }else if(code === 'ES'){
            setCountry(<Country.ES style={style}/>)
        }else if(code === 'ET'){
            setCountry(<Country.ET style={style}/>)
        }else if(code === 'EU'){
            setCountry(<Country.EU style={style}/>)
        }else if(code === 'FI'){
            setCountry(<Country.FI style={style}/>)
        }else if(code === 'FJ'){
            setCountry(<Country.FJ style={style}/>)
        }else if(code === 'FK'){
            setCountry(<Country.FK style={style}/>)
        }else if(code === 'FM'){
            setCountry(<Country.FM style={style}/>)
        }else if(code === 'FO'){
            setCountry(<Country.FO style={style}/>)
        }else if(code === 'FR'){
            setCountry(<Country.FR style={style}/>)
        }else if(code === 'GA'){
            setCountry(<Country.GA style={style}/>)
        }else if(code === 'GB'){
            setCountry(<Country.GB style={style}/>)
        }else if(code === 'GD'){
            setCountry(<Country.GD style={style}/>)
        }else if(code === 'GE'){
            setCountry(<Country.GE style={style}/>)
        }else if(code === 'GF'){
            setCountry(<Country.GF style={style}/>)
        }else if(code === 'GG'){
            setCountry(<Country.GG style={style}/>)
        }else if(code === 'GH'){
            setCountry(<Country.GH style={style}/>)
        }else if(code === 'GI'){
            setCountry(<Country.GI style={style}/>)
        }else if(code === 'GL'){
            setCountry(<Country.GL style={style}/>)
        }else if(code === 'GM'){
            setCountry(<Country.GM style={style}/>)
        }else if(code === 'GN'){
            setCountry(<Country.GN style={style}/>)
        }else if(code === 'GP'){
            setCountry(<Country.GP style={style}/>)
        }else if(code === 'GQ'){
            setCountry(<Country.GQ style={style}/>)
        }else if(code === 'GR'){
            setCountry(<Country.GR style={style}/>)
        }else if(code === 'GS'){
            setCountry(<Country.GS style={style}/>)
        }else if(code === 'GT'){
            setCountry(<Country.GT style={style}/>)
        }else if(code === 'GU'){
            setCountry(<Country.GU style={style}/>)
        }else if(code === 'GW'){
            setCountry(<Country.GW style={style}/>)
        }else if(code === 'GY'){
            setCountry(<Country.GY style={style}/>)
        }else if(code === 'HK'){
            setCountry(<Country.HK style={style}/>)
        }else if(code === 'HM'){
            setCountry(<Country.HM style={style}/>)
        }else if(code === 'HN'){
            setCountry(<Country.HN style={style}/>)
        }else if(code === 'HR'){
            setCountry(<Country.HR style={style}/>)
        }else if(code === 'HT'){
            setCountry(<Country.HT style={style}/>)
        }else if(code === 'HU'){
            setCountry(<Country.HU style={style}/>)
        }else if(code === 'IC'){
            setCountry(<Country.IC style={style}/>)
        }else if(code === 'ID'){
            setCountry(<Country.ID style={style}/>)
        }else if(code === 'IE'){
            setCountry(<Country.IE style={style}/>)
        }else if(code === 'IL'){
            setCountry(<Country.IL style={style}/>)
        }else if(code === 'IM'){
            setCountry(<Country.IM style={style}/>)
        }else if(code === 'IN'){
            setCountry(<Country.IN style={style}/>)
        }else if(code === 'IO'){
            setCountry(<Country.IO style={style}/>)
        }else if(code === 'IQ'){
            setCountry(<Country.IQ style={style}/>)
        }else if(code === 'IR'){
            setCountry(<Country.IR style={style}/>)
        }else if(code === 'IS'){
            setCountry(<Country.IS style={style}/>)
        }else if(code === 'IT'){
            setCountry(<Country.IT style={style}/>)
        }else if(code === 'JE'){
            setCountry(<Country.JE style={style}/>)
        }else if(code === 'JM'){
            setCountry(<Country.JM style={style}/>)
        }else if(code === 'JO'){
            setCountry(<Country.JO style={style}/>)
        }else if(code === 'JP'){
            setCountry(<Country.JP style={style}/>)
        }else if(code === 'KE'){
            setCountry(<Country.KE style={style}/>)
        }else if(code === 'KG'){
            setCountry(<Country.KG style={style}/>)
        }else if(code === 'KH'){
            setCountry(<Country.KH style={style}/>)
        }else if(code === 'KI'){
            setCountry(<Country.KI style={style}/>)
        }else if(code === 'KM'){
            setCountry(<Country.KM style={style}/>)
        }else if(code === 'KN'){
            setCountry(<Country.KN style={style}/>)
        }else if(code === 'KP'){
            setCountry(<Country.KP style={style}/>)
        }else if(code === 'KR'){
            setCountry(<Country.KR style={style}/>)
        }else if(code === 'KW'){
            setCountry(<Country.KW style={style}/>)
        }else if(code === 'KY'){
            setCountry(<Country.KY style={style}/>)
        }else if(code === 'KZ'){
            setCountry(<Country.KZ style={style}/>)
        }else if(code === 'LA'){
            setCountry(<Country.LA style={style}/>)
        }else if(code === 'LB'){
            setCountry(<Country.LB style={style}/>)
        }else if(code === 'LC'){
            setCountry(<Country.LC style={style}/>)
        }else if(code === 'LI'){
            setCountry(<Country.LI style={style}/>)
        }else if(code === 'LK'){
            setCountry(<Country.LK style={style}/>)
        }else if(code === 'LR'){
            setCountry(<Country.LR style={style}/>)
        }else if(code === 'LS'){
            setCountry(<Country.LS style={style}/>)
        }else if(code === 'LT'){
            setCountry(<Country.LT style={style}/>)
        }else if(code === 'LU'){
            setCountry(<Country.LU style={style}/>)
        }else if(code === 'LV'){
            setCountry(<Country.LV style={style}/>)
        }else if(code === 'LY'){
            setCountry(<Country.LY style={style}/>)
        }else if(code === 'MA'){
            setCountry(<Country.MA style={style}/>)
        }else if(code === 'MC'){
            setCountry(<Country.MC style={style}/>)
        }else if(code === 'MD'){
            setCountry(<Country.MD style={style}/>)
        }else if(code === 'ME'){
            setCountry(<Country.ME style={style}/>)
        }else if(code === 'MF'){
            setCountry(<Country.MF style={style}/>)
        }else if(code === 'MG'){
            setCountry(<Country.MG style={style}/>)
        }else if(code === 'MH'){
            setCountry(<Country.MH style={style}/>)
        }else if(code === 'MK'){
            setCountry(<Country.MK style={style}/>)
        }else if(code === 'ML'){
            setCountry(<Country.ML style={style}/>)
        }else if(code === 'MM'){
            setCountry(<Country.MM style={style}/>)
        }else if(code === 'MN'){
            setCountry(<Country.MN style={style}/>)
        }else if(code === 'MO'){
            setCountry(<Country.MO style={style}/>)
        }else if(code === 'MP'){
            setCountry(<Country.MP style={style}/>)
        }else if(code === 'MQ'){
            setCountry(<Country.MQ style={style}/>)
        }else if(code === 'MR'){
            setCountry(<Country.MR style={style}/>)
        }else if(code === 'MS'){
            setCountry(<Country.MS style={style}/>)
        }else if(code === 'MT'){
            setCountry(<Country.MT style={style}/>)
        }else if(code === 'MU'){
            setCountry(<Country.MU style={style}/>)
        }else if(code === 'MV'){
            setCountry(<Country.MV style={style}/>)
        }else if(code === 'MW'){
            setCountry(<Country.MW style={style}/>)
        }else if(code === 'MX'){
            setCountry(<Country.MX style={style}/>)
        }else if(code === 'MY'){
            setCountry(<Country.MY style={style}/>)
        }else if(code === 'MZ'){
            setCountry(<Country.MZ style={style}/>)
        }else if(code === 'NA'){
            setCountry(<Country.NA style={style}/>)
        }else if(code === 'NC'){
            setCountry(<Country.NC style={style}/>)
        }else if(code === 'NE'){
            setCountry(<Country.NE style={style}/>)
        }else if(code === 'NF'){
            setCountry(<Country.NF style={style}/>)
        }else if(code === 'NG'){
            setCountry(<Country.NG style={style}/>)
        }else if(code === 'NI'){
            setCountry(<Country.NI style={style}/>)
        }else if(code === 'NL'){
            setCountry(<Country.NL style={style}/>)
        }else if(code === 'NO'){
            setCountry(<Country.NO style={style}/>)
        }else if(code === 'NP'){
            setCountry(<Country.NP style={style}/>)
        }else if(code === 'NR'){
            setCountry(<Country.NR style={style}/>)
        }else if(code === 'NU'){
            setCountry(<Country.NU style={style}/>)
        }else if(code === 'NZ'){
            setCountry(<Country.NZ style={style}/>)
        }else if(code === 'OM'){
            setCountry(<Country.OM style={style}/>)
        }else if(code === 'PA'){
            setCountry(<Country.PA style={style}/>)
        }else if(code === 'PE'){
            setCountry(<Country.PE style={style}/>)
        }else if(code === 'PF'){
            setCountry(<Country.PF style={style}/>)
        }else if(code === 'PG'){
            setCountry(<Country.PG style={style}/>)
        }else if(code === 'PH'){
            setCountry(<Country.PH style={style}/>)
        }else if(code === 'PK'){
            setCountry(<Country.PK style={style}/>)
        }else if(code === 'PL'){
            setCountry(<Country.PL style={style}/>)
        }else if(code === 'PM'){
            setCountry(<Country.PM style={style}/>)
        }else if(code === 'PN'){
            setCountry(<Country.PN style={style}/>)
        }else if(code === 'PR'){
            setCountry(<Country.PR style={style}/>)
        }else if(code === 'PS'){
            setCountry(<Country.PS style={style}/>)
        }else if(code === 'PT'){
            setCountry(<Country.PT style={style}/>)
        }else if(code === 'PW'){
            setCountry(<Country.PW style={style}/>)
        }else if(code === 'PY'){
            setCountry(<Country.PY style={style}/>)
        }else if(code === 'QA'){
            setCountry(<Country.QA style={style}/>)
        }else if(code === 'RE'){
            setCountry(<Country.RE style={style}/>)
        }else if(code === 'RO'){
            setCountry(<Country.RO style={style}/>)
        }else if(code === 'RS'){
            setCountry(<Country.RS style={style}/>)
        }else if(code === 'RU'){
            setCountry(<Country.RU style={style}/>)
        }else if(code === 'RW'){
            setCountry(<Country.RW style={style}/>)
        }else if(code === 'SA'){
            setCountry(<Country.SA style={style}/>)
        }else if(code === 'SB'){
            setCountry(<Country.SB style={style}/>)
        }else if(code === 'SC'){
            setCountry(<Country.SC style={style}/>)
        }else if(code === 'SD'){
            setCountry(<Country.SD style={style}/>)
        }else if(code === 'SE'){
            setCountry(<Country.SE style={style}/>)
        }else if(code === 'SG'){
            setCountry(<Country.SG style={style}/>)
        }else if(code === 'SH'){
            setCountry(<Country.SH style={style}/>)
        }else if(code === 'SI'){
            setCountry(<Country.SI style={style}/>)
        }else if(code === 'SJ'){
            setCountry(<Country.SJ style={style}/>)
        }else if(code === 'SK'){
            setCountry(<Country.SK style={style}/>)
        }else if(code === 'SL'){
            setCountry(<Country.SL style={style}/>)
        }else if(code === 'SM'){
            setCountry(<Country.SM style={style}/>)
        }else if(code === 'SN'){
            setCountry(<Country.SN style={style}/>)
        }else if(code === 'SO'){
            setCountry(<Country.SO style={style}/>)
        }else if(code === 'SR'){
            setCountry(<Country.SR style={style}/>)
        }else if(code === 'SS'){
            setCountry(<Country.SS style={style}/>)
        }else if(code === 'ST'){
            setCountry(<Country.ST style={style}/>)
        }else if(code === 'SV'){
            setCountry(<Country.SV style={style}/>)
        }else if(code === 'SX'){
            setCountry(<Country.SX style={style}/>)
        }else if(code === 'SY'){
            setCountry(<Country.SY style={style}/>)
        }else if(code === 'SZ'){
            setCountry(<Country.SZ style={style}/>)
        }else if(code === 'TA'){
            setCountry(<Country.TA style={style}/>)
        }else if(code === 'TC'){
            setCountry(<Country.TC style={style}/>)
        }else if(code === 'TD'){
            setCountry(<Country.TD style={style}/>)
        }else if(code === 'TF'){
            setCountry(<Country.TF style={style}/>)
        }else if(code === 'TG'){
            setCountry(<Country.TG style={style}/>)
        }else if(code === 'TH'){
            setCountry(<Country.TH style={style}/>)
        }else if(code === 'TJ'){
            setCountry(<Country.TJ style={style}/>)
        }else if(code === 'TK'){
            setCountry(<Country.TK style={style}/>)
        }else if(code === 'TL'){
            setCountry(<Country.TL style={style}/>)
        }else if(code === 'TM'){
            setCountry(<Country.TM style={style}/>)
        }else if(code === 'TN'){
            setCountry(<Country.TN style={style}/>)
        }else if(code === 'TO'){
            setCountry(<Country.TO style={style}/>)
        }else if(code === 'TR'){
            setCountry(<Country.TR style={style}/>)
        }else if(code === 'TT'){
            setCountry(<Country.TT style={style}/>)
        }else if(code === 'TV'){
            setCountry(<Country.TV style={style}/>)
        }else if(code === 'TW'){
            setCountry(<Country.TW style={style}/>)
        }else if(code === 'TZ'){
            setCountry(<Country.TZ style={style}/>)
        }else if(code === 'UA'){
            setCountry(<Country.UA style={style}/>)
        }else if(code === 'UG'){
            setCountry(<Country.UG style={style}/>)
        }else if(code === 'UM'){
            setCountry(<Country.UM style={style}/>)
        }else if(code === 'US'){
            setCountry(<Country.US style={style}/>)
        }else if(code === 'UY'){
            setCountry(<Country.UY style={style}/>)
        }else if(code === 'UZ'){
            setCountry(<Country.UZ style={style}/>)
        }else if(code === 'VA'){
            setCountry(<Country.VA style={style}/>)
        }else if(code === 'VC'){
            setCountry(<Country.VC style={style}/>)
        }else if(code === 'VE'){
            setCountry(<Country.VE style={style}/>)
        }else if(code === 'VG'){
            setCountry(<Country.VG style={style}/>)
        }else if(code === 'VI'){
            setCountry(<Country.VI style={style}/>)
        }else {
            setCountry(<span>&nbsp;{code}&nbsp;</span>)
        }
    }

    useEffect(() => {
        init();
    },[])//eslint-disable-line

    return (
        <>{country}</>
    )
}